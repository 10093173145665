/** @jsx jsx */
import { jsx, Flex, Styled, useThemeUI } from "theme-ui"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { shade, tint } from "polished"

const NavLink = props => {
  const { theme } = useThemeUI()
  return (
    <Styled.a
      as={Link}
      {...props}
      sx={{
        mx: 2,
        fontSize: 2,
        ":hover": {
          color: shade(0.125, theme.colors.pink),
        },
        "&[aria-current='page']::after": {
          display: "block",
          position: "absolute",
          bottom: -1,
          width: "100%",
          content: "''",
          bg: tint(0.5, theme.colors.pink),
          height: "2px",
        },
      }}
    ></Styled.a>
  )
}

const Header = ({ siteTitle, lang = "fi" }) => (
  <header sx={{ position: "absolute", top: 0, zIndex: 1, width: "100%" }}>
    <Flex
      sx={{
        justifyContent: "space-between",
        alignItems: "baseline",
        p: 4,
        fontSize: [3, null, 4],
      }}
    >
      <Link
        to="/"
        sx={{
          color: "text",
          fontFamily: "heading",
          fontWeight: "heading",
          letterSpacing: "heading",
          textDecoration: "none",
        }}
      >
        {siteTitle}
      </Link>
      <Flex as="nav">
        <NavLink to="/">Etusivu</NavLink>
        <NavLink to="/maiju">Maiju</NavLink>
        {/**
      <Link
          to={lang === "fi" ? "/en" : "/"}
          sx={{
            display: "block",
            color: "text",
            textDecoration: "none",
            fontSize: [1, 2],
            bg: "white",
            py: "2px",
            px: 2,
          }}
        >
          {lang === "fi" ? "In English" : "Suomeksi"}
        </Link>
        */}
      </Flex>
    </Flex>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
