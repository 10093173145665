/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/xjp3nmi.css" />
      </Helmet>
      <Flex
        sx={{
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <main sx={{ width: "100%", flex: "1 1 auto" }}>{children}</main>
        <Flex
          as="footer"
          sx={{
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            width: "100%",
            mt: [4, 5, 6],
            p: [3, 4],
            textAlign: ["center", "left"],
          }}
        >
          <Box sx={{ mb: [4, 0] }}>
            © {new Date().getFullYear()} Maiju Roine
          </Box>
          <Box>
            Kustantaja: Sulasol
            <span
              sx={{
                display: ["block", "inline-block"],
                mx: 3,
                fontSize: 3,
                lineHeight: 1,
                height: [0, "auto"],
                visibility: ["hidden", "visible"],
              }}
            >
              ·
            </span>
            Kuvitus: Lotta Vuorma
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
